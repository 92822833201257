.skills-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0.5rem;
}
.skill-container {
    margin: 0.5rem 2rem 0.5rem 0;
    display: flex;
    justify-content: center;
    padding: 0.4rem 3.5rem 0.4rem 3.5rem;
    background-color: #4749e0;
    color: white;
    font-size: 0.7rem;
    border-radius: 5px;
}
.job-listing-container {
    transition: all 0.5s ease;
}

.job-listing-container:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.251);
}
@media screen and (min-width: 1200px) {
    #mobile {
        display: none;
    }
}
