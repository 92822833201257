
.recent-payment-pay{
    display: flex;
}
.recent-payment-currency{
    margin-right: 1vw;
}
.recent-payment-value{
   font-weight: 700;
}
.person-info{
    text-align: left;
}
.employees-dropdown{
    text-align: left;
}
.upload-btn{
    background-color: #4749e0;
    color: white;
    padding: 0.7vh 3vw 0.7vh 3vw;
    border-radius: 5px;
    margin-left: 7.2vw;
}
.upload-btn:hover{
    background-color: #4338ca;
}
.back-btn-mobile{
    position: absolute;
    margin-top: 2vw;
    margin-left: 4vw;
}
.mobile{
    display: block;
}
.desktop{
    display: none;
}
.summary-container{
    width: 90vw;
    position: fixed;
    top: 10vh;
    left: 5vw;
    box-shadow: 3px 2px 20px rgba(0, 0, 0, 0.156);
}
.close-summary-btn{
    position: absolute;
    top: 3vh;
    right: 5vw;
}
.summary-dim{
    background-color: rgba(27, 23, 68, 0.136);
    backdrop-filter: blur(3px);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.summary-dim{
    cursor: pointer;
}
.payment-summary{
    cursor:default
}
.file-input{
    max-width: 60vw;
}
.value-input{
    margin-top: 0.4vw;
}
.new-payment-fees{
    display: flex;
    margin-bottom: -5vw;
    align-items: flex-end;
}
.new-payment-fee-note{
    color: #00000093;
}
.employee-payment-container{
    width: 100%;
}
.employee-payment-dropdown{
    width: 100%;
    text-align: left;
    padding-left: 1vw;
    padding: 1vh 0vw 1vh 1vw;
    z-index: 100;
}
.employee-payment-dropdown:hover{
    background-color: #4338ca;
    color: white;
}
.error{
    color: rgb(244, 7, 7);
}
.error-field{
    border: 1px solid rgb(255, 0, 0);
}
.choose-currency-label{
    display: flex;
}
.value-img-container{
    display: flex;
    flex-wrap: nowrap;

}
.value-img{
    margin-right: 2vw;
}
.payment-row{
    cursor: pointer;
}
.thead-back{
    position: sticky;
}
.quick-search-container{
    width: 14rem;
    position: absolute;
    top: 10rem;
    right: 1.5rem;
}
.table{
    margin-top: 4rem;
}
.payment-row:hover{
    background-color: #4749e0;
    color: white !important;
}
@media screen and (min-width: 1200px) {
    .close-summary-btn{
        position: absolute;
        top: 4vh;
        right: 2vw;
    }
    .summary-container{
        width: 60vw;
        position: fixed;
        top: 25vh;
        margin-left: 17vw;
    }
    .mobile{
        display: none;
    }
    .desktop{
        display: block;
    }
    .back-btn{
        position: absolute;
        height: auto;
        width: auto;
        margin-top: 2vw;
        margin-left: 2vw;
    }
    .new-payment-fees{
        margin-bottom: -1vw;
        width: 50vw;
    }
    .table{
        margin-top: 0rem;
    }
    .quick-search-container{
        width: 20vw;
        position: absolute;
        top: 5.2rem;
        right: 14rem;
    }
}