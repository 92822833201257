*,
*::after,
*::before {
    scroll-behavior: smooth !important;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* smooth scrolling */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import url('https://fonts.lgoogleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.loader {
    border-top-color: #141414;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.button-loader,
.button-loader:before,
.button-loader:after {
    border-radius: 50%;
}
.button-loader {
    color: #ffffff;
    font-size: 0.2vw;
    text-indent: -99999em;
    margin: 0 auto;
    position: relative;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 1em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.button-loader:before,
.button-loader:after {
    position: absolute;
    content: '';
}
.button-loader:before {
    width: 5.5em;
    height: 10em;
    background: #4f46e5;
    border-radius: 10.2em 0 0 10.2em;
    top: -0em;
    left: -0.9em;
    -webkit-transform-origin: 5.1em 5.1em;
    transform-origin: 5.1em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
}
.button-loader:after {
    width: 8em;
    height: 10em;
    background: #4f46e5;
    border-radius: 0 10.2em 10.2em 0;
    top: -0em;
    left: 5em;
    -webkit-transform-origin: 0.1em 5.1em;
    transform-origin: 0.1em 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.field-error {
    border: 1px solid red;
}

.tooltip {
    position: relative;
}

.tooltip--content {
    position: absolute;
    bottom: 0px;
    left: 50%;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    font-size: 12px;
    color: #000;
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, 80%);
    transition: all 0.3s ease-in-out;

    min-width: 20ch;
    width: min-content !important;
    max-width: 35ch;
}

.tooltip:hover .tooltip--content {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 120%);
}
